@import "../../globals/styles/_variables.scss";.login__form {
  width: 100%;
  margin-top: 30px;

  :global(.ant-form-item) {
    margin: 0;
  }
}

.login__email {
  margin-bottom: 30px;
}

.login__password {
  margin-bottom: 9px;
}

.login__mandatory {
  margin-bottom: 50px;
  font-size: 10px;
  line-height: 10px;
}

.login__submit {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
