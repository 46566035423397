@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables";

.deliveryShowroom__container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 32px;

  :global(.ant-checkbox-inner) {
    border-radius: 0;
    height: 24px;
    width: 24px;
    border-color: $dark-grey;
    margin-top: -1px;
  }
  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: $dark-grey;
  }
  :global(.ant-checkbox-inner:after) {
    width: 8px;
    height: 14px;
    top: 45%
  }
  :global(.ant-select-selection-item) {
    font-weight: 600;
  }
}

.deliveryShowroom__showroomPickup {
  text-align: left;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

.deliveryShowroom__showroomName {
  flex-grow: 1;
  text-align: left;
  line-height: 22px;
  font-weight: 600;
}

.deliveryShowroom__select {
  margin-left: 6px;
  width: 100%;

  :global(.ant-select-selection-search-input) {
    font-size: 16px;
  }
}

.deliveryShowroom__submit {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;

  button {
    margin-bottom: 0;
    margin-top: auto;
  }
  //margin-top: auto;
  //margin-bottom: 0;
}

.DeliveryShowroom__itemList {
  padding: 10px 0 0;
  width: 100%;
  flex-grow: 1;

  margin-bottom: 20px;
}

.DeliveryShowroom__items {
  width: 100%;
}