@import "../../../globals/styles/_variables.scss";.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 120px);

  .logo {
    width: 100px;
    margin-bottom: 40px;
  }

  .welcome {
    font-size: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    line-height: 130%;
    letter-spacing: 0.01em;
    width: 60%;
    margin-bottom: 10px;
  }

  .showroom {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    line-height: 130%;
    margin-top: -15px;
    margin-bottom: 30px;
  }

  .access {
    font-size: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    line-height: 130%;
    letter-spacing: 0.01em;
  }

  .startLogin {
    margin-top: 15px;
    display: flex; justify-content: center; align-items: center;
    width: 240px; padding: 16px;
    font-size: 16px; line-height: 20px; text-align: center; color: white; text-decoration: none;
    background: rgba(0, 0, 0, 0.5); border-radius: 6px;
  }
}