@import "../../../globals/styles/_variables.scss";@import "../../../globals/styles/variables.scss";

.delivery {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.delivery__titleLine {
  width: 80%;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid $black;
}

.delivery__title {
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: -26px;
  background-color: $white;
  margin-left: auto;
  margin-right: auto;
}

.delivery__container {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  padding: 20px 10% 20px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.delivery__container {
  :global(.ant-menu-horizontal) {
    border-bottom: 0.5px solid $dark-grey;
    min-height: 46px;
  }
  :global(.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item) {
    flex-grow: 1;
    padding: 0;

    color: $dark-grey;
    text-align: center;
  }
  :global(.ant-menu-item-selected) {
    span {
      font-weight: 500;
    }
  }
  :global(.ant-menu-title-content) {
    font-weight: 300;
    font-size: 10px;
    line-height: 10px;
  }
  :global(.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after) {
    border-bottom: 2px solid $dark-grey;
  }
  :global(.ant-menu-horizontal>.ant-menu-item:after) {
    left: 0;
    right: 0;
  }
}

.delivery__section {
  & > div {
    display: flex;
  }

  &--checkbox {
    margin-right: 8px;
    
    :global(.ant-checkbox-inner) {
      border-radius: 0;
      height: 16px;
      width: 16px;
      border-color: $dark-grey;
      margin-top: -1px;
    }

    :global(.ant-checkbox-checked .ant-checkbox-inner) {
      background-color: $dark-grey;
    }

    :global(.ant-checkbox-inner:after) {
      width: 6px;
      height: 10px;
      top: 45%
    }
  }

  &--selection {
    margin-top: 2px;
    width: 100%;
  }
}
.delivery_or--container {
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
}
.delivery_or {
  //display: inline-block;
  padding: 8px;
  background-color: white;
  margin: -16px auto 12px;
  text-align: center;
  & p {
    font-size: 16px;
  }
}

.delivery_info {
  font-size: 16px;
  text-align: left;
  font-weight: bold;
}

.delivery__submit {
  margin-top: 10px;
  p {
    text-transform: uppercase;
  }
}

.bottomMenu__button {
  margin-top: 10px;
  a {
    text-decoration: none;
  }
}
.delivery__separator {
  margin-top: 24px;
  margin-bottom: 32px;
  & .delivery__titleLine {
    width: 100%;
  }
}
.delivery__email {
  margin-top: 12px;
}