@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.switchSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;

  font-weight: 300;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.454545px;

  :global(.ant-switch) {
    width: 44px;
    outline: 1px solid $dark-grey;
    background-color: $white;
  }
  :global(.ant-switch-handle::before) {
    background-color: $dark-grey;
    top: -1px
  }
  p {
    flex: 1 1 0;
    text-align: center;
  }
}

.switchSelect__switchContainer {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.readOnly {
  :global(.ant-select-selection-item) {
    color: rgba(0, 0, 0, 0.75);
  }

  :global(.ant-select-arrow) {
    display: none;
  }
}