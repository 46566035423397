@import "../../../globals/styles/_variables.scss";@import "../../../globals/styles/variables";

.DeliveryShowroom__itemList {
  padding: 10px 0 0;
  width: 100%;
  flex-grow: 1;

  margin-bottom: 20px;
}

.DeliveryShowroom__items {
  width: 100%;
}

.DeliveryShowroom__buttons {
  width: 100%;
  margin-top: 20px;
}
.DeliveryShowroom__otherAddress {
  margin-bottom: 20px;
}

